.cookiesWrapper {
  width: 100%;
  background: rgba(0, 0, 0, .8);
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 16px 0;
  z-index: 2001;

  .mainWrapper {
    width: 75.2%;
    margin: 0 auto;
    position: relative;

    .title {
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: #FFF;
    }

    .content {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #FFF;
      margin-top: 16px;

      .link {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #FFF;
        margin-top: 16px;
        text-decoration: underline;
      }
    }

    .close {
      width: 20px;
      height: 20px;
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
    }

    .btnWrapper {
      margin-top: 16px;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.showCookiesManage {
  transform: translate(0, 100%);
  animation: slideUp .5s ease-out forwards;
}

.hideCookiesManage {
  transform: translate(0, 100%);
  // animation: slideDown .5s ease-out forwards;
}

@keyframes slideUp {
  from {
    transform: translate(0, 100%);
  }

  to {
    transform: translate(0, 0);
  }
}

// @keyframes slideDown {
//   from {
//     transform: translate(0, 0%);
//   }

//   to {
//     transform: translate(0, 100%);
//   }
// }