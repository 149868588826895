.jdi-footer {
  border-top: #0000000f;
  color: #1a1a1a;

  .w {
    margin: 0 auto;
    width: 1264px;
  }

  img {
    display: inline;
  }

  .footer-top-wrap {
    min-width: 1264px;
    background-color: #eef0f6;

    .footer-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;

      .left-box {
        display: flex;
        align-items: center;

        .slogan {
          margin-left: 16px;
          color: var(--sys-color-Primary700);
          font-weight: bold;
          font-size: 16px;
        }
      }

      .international-wrap {
        display: flex;

        .item {
          display: flex;
          align-items: center;
          margin-right: 80px;
        }

        .item:last-child {
          margin-right: 0;
        }

        .ico {
          margin-right: 8px;
        }
      }
    }
  }

  .footer-main-wrap {
    min-width: 1264px;
    background-color: #f5f6fa;

    .footer-main {
      display: flex;
      padding: 32px 0;

      .title {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        margin-bottom: 14px;
      }

      .left-container {
        flex: 1;
        display: flex;
        flex-direction: row;

        .vertical {
          margin-right: 180px;

          &:last-child {
            margin-right: 0;
          }
        }

        .links {
          font-size: 14px;

          li {
            margin-bottom: 16px;
          }

          a:hover {
            color: #ff3f4f;
          }
        }
      }

      .right-container {
        width: 320px;

        .email {
          margin-bottom: 14px;
          display: flex;
        }

        .email-input {
          margin-right: 4px;
          background-color: #fff;
          flex: 1;
        }

        .tips {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }

  .footer-ad-wrap {
    min-width: 1264px;
    background-color: #f5f6fa;
    border-top: 1px solid #0000000f;
    border-bottom: 1px solid #0000000f;

    .footer-ad {
      font-size: 14px;
      color: #888b94;
      padding: 24px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        margin-right: 16px;
      }

      .findLogo {
        display: flex;
        align-items: center;
      }

      .findLogo a {
        margin-right: 12px;

        &:last-child {
          margin-right: 0;
        }
      }

      .logo a {
        margin-right: 12px;

        &:last-child {
          margin-right: 0;
        }
      }

      .download {
        display: flex;
        align-items: center;
        //margin-right: 110px;
        flex: 0 0 auto;
      }

      .find-us {
        display: flex;
        align-items: center;
        //margin-right: 110px;
        flex: 0 0 auto;
      }

      .support {
        display: flex;
        align-items: center;
        flex: 0 0 auto;
      }
    }
  }

  .copyright {
    text-align: center;
    font-size: 12px;
    color: #888b94;
    padding: 12px 0;
    background-color: #f5f6fa;
    min-width: 1264px;

    a {
      margin-left: 16px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #888B94;
      text-decoration: underline;
      cursor: pointer;
    }
    a.line2 {
        margin-left: 6px;
    }
  }
}