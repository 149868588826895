.message-dialog{
  .title{
    padding: 12px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--sys-Text-Anti);
    .arrow-right{
      position: absolute;
      top: 12px;
      right: 12px;
    }
    span{
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      text-align: center;
    }
  }
  .list{
    width: 359px;
    padding: 8px;
    gap: 8px;
    display: flex;
    flex-direction: column;
    .fixedlist{
      width: 359px;
      border-radius: 8px;
      background-color: var(--sys-Text-Anti);
    }
    .floatlist{
      width: 359px;
      border-radius: 8px;
      background-color: var(--sys-Text-Anti);
    }
  }
}

.message-detail{
  .detail-title{
    height: 44px;
    padding-left: 12px;
    padding-right: 12px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--sys-Text-Anti);
  }
  .vector{
    transform: rotate(-90deg);
  }
  span{
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
  }
}

.message-frame{
  position: fixed;
  bottom: 104.5px;
  right: 74px;
  width: 375px;
  height: 80%;
  max-height: 720px;
  border-radius: 8px;
  background-color: var(--sys-background-MainGray);
  border: 1px solid var(--sys-Surface-Outline100);
}

.message-container{
  width: 375px;
  height: 676px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message-list{
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  white-space: nowrap;
  // scrollbar-width: none;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  background: #F5F6FA;

  .loading{
    width: 100%;
    height: 36px;
    padding: 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loading-icon{
    width: 20px;
    height: 20px;
  }

  .reach-bottom{
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #C2C4CC;
  }
}

.page-error{
  display: flex;
  align-items: center;
  justify-content: center;
}

.pover-btn {
  display: flex;
  align-items: center;
  color: rgba(106, 106, 106, 1);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  z-index: 2;
  cursor: pointer;
  &:hover {
    color: var(--sys-color-Primary800);
    .more-icon{
      .svg,
      path{
        fill: var(--sys-color-Primary800);
      };
    }
  }
}

.more-icon {
  width: 20px;
  height: 20px;
  z-index: 2;
}

.cursor{
  cursor: pointer;
}

.hover{
  &:hover{
    .svg,
    path{
      stroke: var(--sys-color-Primary800);
    };
    rect{
      stroke: var(--sys-color-Primary800);
    };
  }
}