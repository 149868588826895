.container {
  padding: 16px;
  // width: 880px;
  background: #FFFFFF;
  border-radius: 8px;
  .title{
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: #1A1A1A;
  }
  .countryRegion{
    margin-top: 16px;
    margin-bottom: 8px;
    //styleName: Base/14 Regular;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #1A1A1A;    
  }
  .flexTop{
    display: flex;
    // margin-top: 16px;
    align-items: center;
  }
  .flex9{
    display: flex;
    margin-top: 9px;
    align-items: center;
  }
  .setText{
    margin-left: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color:#1A1A1A;
  }
  .searchIcon{
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
  .searchText{
    position: absolute;
    left: 38px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #505259;
  }
  .buttonCheck {
    width: 412px;
    height: 40px;
    padding: 10px 16px;
    border-radius: 4px;
    margin-top: 16px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-transform: capitalize;
    color: #FFF;
    background: #CC0C1C;
  }
  .buttonCheckRight {
    width: 412px;
    height: 40px;
    padding: 10px 16px;
    border-radius: 4px;
    margin-top: 16px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-transform: capitalize;
    color: #FFF;
    background: #CC0C1C;
  }
  .bottomFloat {
    height: 68px;
    // position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
  }

  .closeButton {
    width: 62px;
    height: 32px;
    padding: 8px 16px 8px 16px;
    gap: 4px;
    border-radius: 4px;
    background: #CC0C1C;
    font-size: 14px;
    font-weight: 400;
    // margin-right: 30px;
    line-height: 18px;
    text-align: left;
    color: #FFF;
    text-transform: capitalize; 
  }

  .searchBottomItem{
    width: 100%;
    height: 36px;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: #1A1A1A;
  }
  .searchBottomItemEntry{
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: #1869F5;
  }
}