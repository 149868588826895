.wrap {
  position: relative;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .img_mask {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.02);
  }
  .success_img {
    width: 100%;
    height: 100%;
    font-size: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
  }
  .error_img_wrap {
    width: 100%;
    height: 100%;
    font-size: 0;
    .error_img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  // .error_img {
  //   width: 100%;
  //   text-align: center;
  //   font-size: 0;
  //   img {
  //     width: 100%;
  //   }
  // }
}
.wrap:hover:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.1);
}
