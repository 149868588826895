.reviewListWrapper {
    .reviewsItemBlock {
      margin-bottom: 20px;
    }
  
    .reviewsItem {
      margin-bottom: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .reviewsAvatar{
        display: flex;
      }
    }
  
    .reviewsImg {
      margin-right: 8px;
      width: 36px;
      height: 36px;
      border-radius: 99px;
    }
  
    .reviewsName {
      margin-bottom: 2px;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      margin: 0 6px;
    }
  
    .reviewsDate {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: rgba(136, 139, 148, 1);
    }
  
    .reviewsMsg {
      color: rgba(26, 26, 26, 1)
    }
  
    .reviewsContent {
      margin-bottom: 12px;
      color: rgba(26, 26, 26, 1);
      word-wrap: break-word;
      white-space: pre-wrap;
    }
  
    .basicButton {
      height: 20px;
      border-radius: 4px;
      display: flex;
      justify-content: end;
      align-items: center;
      cursor: pointer;
  
      .btn {
        display: flex;
        align-items: center;
        color: rgba(106, 106, 106, 1);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        &.active {
          .zan-icon {
            background-size: 100% 100%;
          }
        }
        &:hover {
          &:not(.active) {
            color: var(--sys-color-Primary800);
            .zanIcon {
              background-size: 100% 100%;
            }
    
            .pinglunIcon {
              background-size: 100% 100%;
            }
    
            .moreIcon {
              background-size: 100% 100%;
            }
          }
  
          
        }
      }
  
      .line {
        margin: 0 8px;
        width: 0;
        height: 12px;
        border-right: 1px solid rgba(224, 225, 229, 1);
      }
    }
  
    .zanIcon {
      width: 14px;
      height: 15px;
      background-size: 100% 100%;
    }
  
    .pinglunIcon {
      // width: 14px;
      // height: 14.45px;
      background-size: 100% 100%;
    }
  
    .moreIcon {
      width: 20px;
      height: 20px;
      background-size: 100% 100%;
    }
  
    .mar {
      margin-right: 5px;
    }
  
    .mediaZone {
      display: flex;
      gap: 12px;
      margin-bottom: 8px;
      flex-wrap: wrap;
      
      img {
        width: 140px;
        height: 140px;
        object-fit: cover;
        border-radius: 4px;
        cursor: pointer,
      }
    }
  }

  .secondaryReplyList {
    padding-top: 8px;
    padding-left: 10px;
    .child {
      margin-bottom: 8px;
      .childHeader {
        display: flex;
        justify-content: space-between;
        line-height: 18px;
        margin-bottom: 8px;
        .avatar {
          display: flex;
          align-items: center;
          span {
            margin-left: 6px;
            font-size: 13px;
          }
          em {
            margin: 0 8px;
            font-style: normal;
            color: #9D9D9D;
            font-size: 13px;
          }
          .tag{
            font-weight: 400;
            font-size: 10px;
            line-height: 10px;
            letter-spacing: 0%;
            text-align: center;
            color: #1869F5;
            border-radius: 2px;
            background-color: #EAF5FD;
            padding: 2px 4px;
            margin-left: 6px;
          }
          .tagAuthor{
            color: #CC6B0A;
            background-color: #FFF8EA;
          }
          .tagJoy{
            color: #1869F5;
            background-color: #EAF5FD;
          }
        }
        .dateTime {
          font-size: 12px;
          color: rgba(157, 157, 157, 1);
        }
      }
      .content {
        display: flex;
        justify-content: space-between;
        gap: 60px;
        .staticText {
          flex: 1;
          font-size: 13px;
          span {
            line-height: 18px;
            word-break: break-all;
            white-space: pre-wrap;
            padding: 2px 0;
          }
        }
        .replyNickName {
          color: rgba(136, 139, 148, 1);
          margin-right: 4px;
        }
        .replyBtn {
          display: flex;
          gap: 12px;
          align-items: center;
          span {
            font-size: 12px;
            cursor: pointer;
            color: rgba(106, 106, 106, 1);
            &:hover{
              color: var(--sys-color-Primary800);
            }
          }
        }
      }
    }
  }
.commentReply {
    position: relative;
    margin-top: 20px;
    margin-bottom: 8px;
  
    .title {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 12px;
    }
  
    textarea {
      display: block;
      width: 100%;
      border-radius: 4px;
      background-color: rgba(247, 248, 252, 1);
      font-size: 16px;
      padding: 12px;
      line-height: 24px;
      height: 60px;
      margin-bottom: 8px;
      border: none;
      resize: none;
    }
  
    .num {
      position: absolute;
      top: 36px;
      right: 8px;
      font-size: 12px;
      color: rgba(194, 196, 204, 1);
  
    }
  
    .opt {
      display: flex;
      justify-content: flex-end;
      margin: 0 auto;
      
      .submitbtn {
        min-width: 64px;
        font-size: 12px;
        padding: 0 8px;
        border-radius: 4px;
        line-height: 28px;
        text-align: center;
        font-weight: 400;
        color: #fff;
        background: linear-gradient(90deg, #FF475D 0%, #FF0F23 100%);
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      }
    }
  }