
.UK_product_card {
  width: 240px;
  border-radius: 4px;
  padding-bottom: 12px;
  position: relative;
  cursor: pointer;
  &:hover::after{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    content: '';
    position: absolute;
    border: 1px solid #990915;
    border-radius: 4px;
    pointer-events: none;
    z-index: 11;
  }

  .UK_imgTips {
    position: absolute;
    z-index: 10;

    .UK_topTagBox {
      position: relative;
      width: 36px;
      height: 45px;

      .UK_topTagIcon {
        width: 100%;
        height: 100%;
      }

      .UK_topTagNum {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 24px;
        font-weight: 400;
        line-height: 28px;
        color: #fff;
      }
    }
  }

  .UK_image_wrapper {
    position: relative;
    width: 100%;
    font-size: 0;
    cursor: pointer;

    .UK_skuImg {
      width: 100%;
      aspect-ratio: 1 / 1;
      text-align: center;
      object-fit: contain;
      border-radius: 4px;
    }

    .op50 {
      opacity: 0.5;
    }

    .UK_mask {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02));

    }

    .UK_outOfStock {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      z-index: 12;
      background: linear-gradient(0deg, rgba(225, 225, 225, 0.02), rgba(225, 225, 225, 0.02));
      display: flex;
      justify-content: center;
      align-items: center;

      .UK_outOfStock_text {
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #fff;
        text-align: center;
        padding: 5px 11px;
        background-color: #00000080;
        border-radius: 4px;
      }
    }
  }

  .UK_moreModel_wrapper {
    display: flex;
    margin-top: 4px;
    gap: 4px;
    justify-content: center;

    .UK_moreModel {
      width: 40px;
      height: 40px;
      border-radius: 2px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 100%;
        height: 100%;
        aspect-ratio: 1 / 1;
        text-align: center;
        object-fit: contain;
        border-radius: 2px;
      }

      .UK_mask {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02));

        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0px;
          left: 0px;
        }
      }

      .UK_moreModelNum {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #888B94;
      }
    }
  }
}

.UK_name_wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  // padding: 0 12px;
  cursor: pointer;

  .UK_name_box {
    flex: 1;
    text-align: left;
    font-weight: 400;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden; //溢出内容隐藏
    text-overflow: ellipsis; //文本溢出部分用省略号表示
    display: -webkit-box; //特别显示模式
    -webkit-line-clamp: 2; //行数
    line-clamp: 2;
    -webkit-box-orient: vertical; //盒子中内容竖直排列
    color: #19191B;
    margin: 0 12px;
  }

  .UK_productLabelDom {
    display: inline-block;
    margin-right: 4px;
  }

  .UK_name {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    vertical-align: top;
    word-break: break-word;
  }

  .UK_pricePromotionTag {
    margin-right: 2px;
    padding: 0 2px;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    color: #fff;
    background: linear-gradient(90deg, #FF475D 0%, #FF0F23 100%);
  }
}

.UK_saleInfo {
  margin-top: 6px;
  overflow: hidden;
  color: #888B94;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding: 0 12px;
}

.UK_score {
  display: flex;
  align-items: center;
  text-align: left;
  margin-top: 6px;
  padding: 0 12px;

  .UK_star {
    display: flex;
    align-items: center;
    margin-right: 6px;
    .UK_starIcon {
      margin-right: 2px;
    }
  }

  .UK_saleNum {
    flex: 1;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #888B94;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.UK_price_wrapper {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 0 12px;
  margin-top: 6px;

  .UK_left {
    width: 100%;
    flex: 1;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;

    .UK_realPrice {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      height: 28px;
      font-family: JDZhengHT-EN;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      text-align: left;
      color: #1a1a1a;
      white-space: pre-wrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .UK_originalPrice {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      height: 18px;
      font-family: JDZhengHT-EN;
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      color: #1a1a1a;
      white-space: pre-wrap;
      overflow: hidden;
      margin-top: 6px;

      .UK_saleTipsDom {
        display: inline-block;
        margin-left: 4px;
        color: #888B94;
      }
    }
  }

  .UK_right {
    width: 32px;
    .UK_cart {
      width: 32px;
      height: 32px;
      cursor: pointer;
      color: #CC0C1C;
      
      &:hover {
        color: #990915;
      }
    }
  }
}

.UK_activityRow {
  width: 100%;
  height: 18px;
  padding: 0 12px;
  box-sizing: border-box;
  font-family: JDZhengHT-EN;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  overflow: hidden;
  margin-top: 6px;
  text-align: left;
  overflow: hidden; //溢出内容隐藏
  text-overflow: ellipsis; //文本溢出部分用省略号表示
  white-space: nowrap;
}
