.cms_floor {
  margin: 0 auto;
  box-sizing: border-box;
}
.cms_content {
  display: flex;
  margin: 0 auto 0 auto;
}
.cms_cell {
  width: 100%;
}
