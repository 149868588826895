.container {
    display: flex;
    margin-bottom: 68px;

    .load-page {
        display: flex;
        justify-content: center;
        min-height: 350px;
        min-width: 650px;
        border-radius: 8px;
        // background-color: #FFFFFF;
        margin: 16px 0;
        position: relative;
        text-align: center;
    }

    .right {
        margin-left: 20px;

        .name {
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            text-align: left;
            color: #1A1A1A;
        }

        .price-item {
            margin-top: 16px;
            margin-bottom: 16px;
            display: flex;
            align-items: baseline;

            .price {
                font-family: JDZhengHT-EN;
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                color: #1A1A1A;

                .big-price {
                    font-family: JDZhengHT-EN;
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 24px;
                    text-align: left;
                    color: #1A1A1A;
                }
            }

            .origin-price {
                margin-left: 4px;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                text-align: left;
                color: #888B94;
            }

            .origin-price span {
                text-decoration: line-through;
            }

            .text-container {
                margin-left: 8px;
                flex: 1;
                min-width: 0;
                cursor: pointer;
                // white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
    
                .tag {
                    height: 16px;
                    margin-right: 4px;
                    padding: 0 5px;
                    border-radius: 2px;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 14px;
                    text-align: center;
                    color: white;
                    background: #CC0C1C;
                }
            }
        }

        .color-space {
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            text-align: left;
            color: #000000;

            .color-item {
                font-weight: 600;
            }
        }
        .content {
            margin-top: 16px;
            margin-bottom: 16px;
            height: 36px;
            display: flex;
            gap: 12px;
            flex-wrap: wrap;

            .item {
                cursor: pointer;
                transition: color 0.3s ease;
                width: auto;                    
                display: flex;
                align-items: center;
                padding: 4px 12px;
                border: 1.5px solid #C2C4CC;
                border-radius: 4px;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                color: #1A1A1A;

                &.active {
                    border: 1.5px solid #CC0C1C;
                    color: #CC0C1C;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 18px;
                }

                &.disabled {
                    border: 1.5px dashed #E0E1E5;
                    color: #C2C4CC;
                    opacity: 0.5;
                }
            }
            .item:hover{
                cursor: pointer;
                font-weight: 700;
                color: #990915;
            }
        }
    }

    .button {
        margin-top: 40px;
        display: flex;

        .left {
            width: 93px;
            height: 48px;
            padding: 10px 16px;
            gap: 4px;
            border-radius: 4px;
            // font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            color: #FFFFFF;
            text-transform: capitalize;
            background: #CC0C1C;
        }

        .right {
            margin-left: 8px;
            width: 85px;
            height: 48px;
            padding: 10px 16px 10px 16px;
            gap: 4px;
            border-radius: 4px;
            color: #1A1A1A;
            border: 1px solid #E0E1E5;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            text-transform: capitalize;
            background: #fff;
        }
    }
    .buttonContainer {
        height: 68px;
        background-color: #FFFFFF;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        // margin-left: 21px;
        justify-content: flex-end;
        align-items: center;

        .add {
            // width: 93px;
            margin-right: 24px;
            height: 48px;
            padding: 10px 16px;
            gap: 4px;
            border-radius: 4px;
            font-weight: 400;
            line-height: 24px;
            color: #FFFFFF;
            text-transform: none;
            background: #CC0C1C;
        }

        .cancel {
            margin-right: 8px;
            width: 85px;
            height: 48px;
            padding: 10px 16px;
            gap: 4px;
            border-radius: 4px;
            color: #1A1A1A;
            border: 1px solid #E0E1E5;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            text-transform: none;
            background: #fff;
        }
      }
}