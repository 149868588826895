.container {
  // padding: 16px;
  // width: 880px;
  background: #FFFFFF;
  // padding-bottom: 100px;
  border-radius: 12px;
  .flexRight{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  .title{
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: #1A1A1A;
  }
  .info{
    margin-top: 8px;
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: justified;
    color: #1A1A1A;
  }
  .add{
    // width: 132px;
    height: 28px;
    padding: 5px 8px 5px 8px;
    gap: 4px;
    display: flex;
    // align-items: center;
    border-radius: 4px;
    border: 1px solid #CC0C1C;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #CC0C1C;
    text-transform: none;
  }
  .flexLeft{
    display: flex;
    align-items: start;
    // width: 418px;
    padding: 12px;
    height: 88px;
    gap: 12px;
    border-radius: 4px;
    border: 1px solid #CC0C1C
  }
  .flexLeftGray{
    display: flex;
    align-items: start;
    // width: 418px;
    padding: 12px;
    height: 88px;
    gap: 12px;
    border-radius: 4px;
    border: 1px solid #E0E1E5
  }
  .box {
    // padding: 12px;
    background: #FFFFFF;
    width: 350px;
    .country{
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      text-align: left;
      color: #000000;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .phone{
      margin-left: 8px;
      font-size: 14px;
      font-weight: 400;
      margin-right: 5px;
      line-height: 18px;
      text-align: left;
      color: #1A1A1A;
      white-space: nowrap;
    }
    .address{
      margin-top: 8px;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #505259;
      word-break: break-all; /* 允许在任意字符处换行 */
    }
    .flex{
      display: flex;
      align-items: center;
    }
  }

  .bottomFloat {
    height: 68px;
    background-color: #FFFFFF;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 835px;
    display: flex;
    // padding-left: 16px;
    // padding-right: 16px;
    margin-left: 21px;
    justify-content: space-between;
    align-items: center;
  }

  .buttonContainer {
    height: 68px;
    background-color: #FFFFFF;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 835px;
    display: flex;
    margin-left: 21px;
    justify-content: flex-end;
    align-items: center;
  }

  .closeButton {
    width: 62px;
    height: 32px;
    padding: 8px 16px 8px 16px;
    gap: 4px;
    border-radius: 4px;
    background: #CC0C1C;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #FFFFFF;
    text-transform: capitalize;    
  }

  .no-scrollbars {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  .no-scrollbars::-webkit-scrollbar {
    display: none;
  }
  
  .postCode{
    //styleName: Large/16 Bold;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #000000;
  }
}