.tooltipText {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #505259;
  word-break: break-all; /* 允许在任意字符处换行 */
}

.displayTxt {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}