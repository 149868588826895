.loadingIcon {
  display: inline-block;
  font-size: 0;
  line-height: 0;
  width: 40px;
  height: 40px;
  animation: spin 1s infinite linear;
  user-select: none;

  img {
    width: 100%;
    height: 100%;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}