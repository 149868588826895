.common{
  width: 100%;
  position: relative;
  .date {
    font-size: 12px;
    line-height: 16px;
    padding: 8px 0;
    text-align: center;
    color: #888B94;
  }
  .frame{
    gap: 12px;
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    background: white;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title{
        font-size: 16px;
        line-height: 20px;
        max-width: 300px;
        font-weight: bold;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
        text-overflow: ellipsis;
      }
    }
    .content{
      gap: 8px;
      height: 60px;
      display: flex;
      align-items: flex-start;
      img{
        width: 60px;
        height: 60px;
        border-radius: 2px;
      }
      span{
        max-height: 48px;
        min-width: 258px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        text-align: left;
        color: #888B94;
        white-space: normal;
        // word-break: break-all;
        word-wrap: break-word;
        overflow: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
}
.pricedrop{
  width: 100%;
  position: relative;
  .date {
    // height: 16px;
    font-size: 12px;
    line-height: 16px;
    padding: 8px 0;
    text-align: center;
    color: #888B94;
  }
  .frame{
    gap: 12px;
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    background: white;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title{
        font-size: 16px;
        line-height: 20px;
        max-width: 300px;
        font-weight: bold;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
        text-overflow: ellipsis;
      }
    }
    .content{
      height: 80px;
      display: flex;
      align-items: flex-start;
      img{
        width: 80px;
        height: 80px;
        border-radius: 4px;
      }
      .content-frame{
        gap: 6px;
        padding-right: 0;
        padding: 10px 8px;
        display: flex;
        flex-direction: column;
        .sku-name{
          max-height: 36px;
          font-size: 14px;
          line-height: 18px;
          font-weight: 400;
          text-align: left;
          white-space: normal;
          // word-break: break-all;
          word-wrap: break-word;
          overflow: hidden;
          overflow-y: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .price-drop{
          gap: 4px;
          display: flex;
          font-weight: 700;
          img{
            width: 12px;
            height: 16.8px;
          }
          span{
            font-size: 12px;
            line-height: 16px;
            overflow: hidden;
            white-space: nowrap;
            display: inline-block;
            text-overflow: ellipsis;
            color: var(--sys-color-Primary700);
          }
        }
      }
    }
  }
}
.promotion{
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  .date {
    // height: 16px;
    font-size: 12px;
    line-height: 16px;
    padding: 8px 0;
    text-align: center;
    color: #888B94;
  }
  .content{
    gap: 12px;
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    background: white;
    .promotion-title{
      display: flex;
      justify-content: space-between;
    }
    span{
      font-size: 16px;
      line-height: 20px;
      font-weight: bold;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
      text-overflow: ellipsis;
    }
    em{
      font-size: 12px;
      line-height: 16px;
      max-height: 32px;
      font-weight: 400;
      font-style: normal;
      text-align: left;
      overflow: hidden;
      overflow-y: hidden;
      white-space: normal;
      text-overflow: ellipsis;
      // word-break: break-all;
      word-wrap: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      color: var(--sys-Text-Tertiary);
    }
    img{
      height: 120px;
      border-radius: 4px;
    }
  }
}