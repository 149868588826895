.reviewList {
  width: calc(100% - 240px);
  flex: 1;
  background-color: #fff;
  border-radius: 8px;
}

.title_container {
  display: flex;
  justify-content: space-between;
  padding: 24px;

  .title {
    display: inline-block;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
  }

  .eidtBtns {
    display: flex;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: right;
    color: #888b94;

    .eidtBtn {
      margin-left: 20px;
      cursor: pointer;
    }

    .deleteBtn {
      margin-left: 20px;
      cursor: pointer;
      color: #ff0f23;
    }
    .disable {
      color: #c2c4cc;
      cursor: not-allowed;
    }
  }
}

.pagination_wrap {
  display: flex;
  justify-content: right;
  padding: 16px;
}

.tab_nav {
  width: 100%;
  height: 32px;
  padding: 12px 16px;
  box-sizing: content-box;

  .cate_nav_list {
    height: 32px;
    display: flex;
    font-size: 18px;
    color: #505259;
    gap: 24px;
  }

  .cate_nav_item {
    position: relative;
    line-height: 24px;

    &:hover {
      cursor: pointer;

      &:not(.active) {
        color: var(--sys-color-Primary800);
      }
    }

    &.active {
      color: #1a1a1a;
      font-weight: bold;

      &:after {
        content: "";
        position: absolute;
        width: 36px;
        height: 3px;
        border-radius: 1px;
        left: 50%;
        bottom: 0;
        background: #1a1a1a;
        transform: translateX(-50%);
      }
    }
  }
}

.reviewContentWrapper {
  .content {
    padding: 0 16px;
  }
}
.banner_wrap {
  width: 100%;
  height: 34px;
  padding: 0 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(
    90.22deg,
    rgba(255, 246, 246, 0.5) -4.85%,
    rgba(255, 193, 193, 0.5) 100%
  );
  margin: 16px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  .banner_item {
    display: flex;
    align-items: center;
    span {
      margin: 0 4px;
    }
  }
  .banner_r_text {
    color: #888b94;
    cursor: pointer;
  }
}
.orderWrapper {
  .orderItem {
    border: 1px solid rgba(240, 241, 242, 1);
    border-radius: 4px;
    margin-bottom: 16px;
  }

  .header {
    display: flex;
    line-height: 48px;
    padding: 0 16px;
    background: rgba(245, 246, 250, 1);
    border-bottom: 1px solid rgba(240, 241, 242, 1);
    justify-content: space-between;
    .colums1 {
      flex: 1;
      display: flex;
      justify-content: space-between;
    }
    .colums2 {
      font-size: 14px;
      color: rgb(136, 139, 148);
      .label {
        margin-right: 4px;
      }
    }
    .item {
      margin-right: 24px;
      span {
        margin-right: 8px;
        font-size: 14px;
        &:first-child {
          color: rgba(136, 139, 148, 1);
        }
        &:last-child {
          color: rgba(26, 26, 26, 1);
        }
      }
    }
  }
  .product {
    padding: 12px 16px;
    .item {
      margin-bottom: 16px;
      width: 80%;
      cursor: pointer;
      .colums1 {
        display: flex;

        .productTitle {
          font-size: 16px;
          color: rgba(26, 26, 26, 1);
          .text {
            white-space: nowrap;
            max-width: 674px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .dimensions {
          color: rgba(136, 139, 148, 1);
          margin-top: 4px;
          font-size: 14px;
        }
      }
      .starWrapper{
        display: flex;
        align-items: center;
      }
    }
  }
  .reviewButtonWrapper{
    display: flex;
    justify-content: flex-end;
  }
}
.write_review_block {
  width: 1264px;
  margin: 0 auto 32px;

  .content_wrapper {
    margin-top: 32px;
    background: #fff;
    border-radius: 8px;
    padding: 0 16px;
    padding-bottom: 20px;
    .header {
      line-height: 64px;
      font-size: 24px;
      font-weight: 700;
      padding-left: 8px;
    }
    .sumary {
      display: flex;
      width: 1232px;
      margin: 0 auto;
      border-radius: 8px;
      background: rgba(247, 248, 252, 1);
      height: 140px;
      padding: 24px 24px 28px 24px;
    }
  }
}

.section {
  padding: 24px;
  border-radius: 8px;
  border: 1px solid rgba(240, 241, 242, 1);
  margin-top: 16px;
  display: flex;
  .graph {
    display: flex;
    flex-direction: column;
    align-items: center;
    .imgWrapper {
      width: 120px;
      height: 120px;
      margin-bottom: 16px;
    }
    .title {
      max-width: 320px;
      margin-bottom: 16px;
    }
    .dimensions {
      color: rgba(136, 139, 148, 1);
    }
  }
  .form_item {
    display: flex;
    line-height: 20px;
    margin-bottom: 16px;
    .textLabel {
      width: 270px;
      font-size: 16px;
    }
  }
}

.commentReply {
  position: relative;
  margin-top: 12px;
  textarea {
    display: block;
    width: 100%;
    border-radius: 4px;
    background-color: rgba(247, 248, 252, 1);
    font-size: 16px;
    padding: 12px;
    line-height: 24px;
    height: 100px;
    border: none;
    resize: none;
  }

  .num {
    position: absolute;
    bottom: 8px;
    right: 8px;
    font-size: 12px;
    color: rgba(194, 196, 204, 1);
  }
}

// 通用分栏
.colums {
  position: relative;
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: flex-start;
  font-size: 14px;
  &:first-child {
    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 1px;
      background-color: rgba(0, 0, 0, 0.06);
    }
  }
  &:last-child {
    padding-left: 32px;
  }
  .label {
    color: rgba(136, 139, 148, 1);
    margin-right: 8px;
  }
  .service {
    line-height: 20px;
    &:first-child {
      & > span {
        font-weight: 700;
      }
    }
    & > span {
      font-size: 16px;
    }
    display: flex;
  }
  .sumaryScore {
    position: absolute;
    top: 0;
    left: 300px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
// 图像Image css
.imgWrapper {
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  margin-right: 16px;
  width: 60px;
  height: 60px;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.02);
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

// 超出两行省略
.line2Overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.lineOverflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// 重新上传
.imgReUpload {
  display: inline-block;
  width: 100px;
  height: 100px;
  background: #f5f6fa;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  .imgReUpload_span {
    height: 28px;
    padding: 0 5px;
    line-height: 28px;
    text-align: center;
    color: #1a1a1a;
    border-radius: 4px;
    cursor: pointer;
    background-color: #fff;
    position: relative;
    z-index: 3;
    font-size: 13px;
    white-space: nowrap;
    border: 1px solid #00000000;
    &:hover {
      color: var(--sys-color-Primary800);
      border: 1px solid var(--sys-color-Primary800);
    }
  }
}
// 上传
.mediaContainer {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  // 加载中
  .imageLoading {
    width: 100px;
    height: 100px;
    background: #f5f6fa;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      animation: spin 1s infinite linear;
    }

    @keyframes spin {
      0% {
        transform: rotate(360deg);
      }
      20% {
        transform: rotate(288deg);
      }
      40% {
        transform: rotate(216deg);
      }
      60% {
        transform: rotate(144deg);
      }
      80% {
        transform: rotate(72deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }
  }

  .uploadImgWrapper {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.03);
    width: 100px;
    height: 100px;
    border-radius: 4px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    video {
      width: 100px;
      height: 100px;
      border-radius: 4px;
      object-fit: cover;
    }

    .imgWrapper {
      width: 100px;
      height: 100px;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      &:hover {
        opacity: 1;
      }
      .viewIcon {
        width: 57px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        color: #1a1a1a;
        border-radius: 4px;
        cursor: pointer;
        background-color: #fff;
        position: relative;
        z-index: 1;
        border: 1px solid #00000000;
        &:hover {
          color: var(--sys-color-Primary800);
          border: 1px solid var(--sys-color-Primary800);
        }
      }
    }
    // 重新上传按钮

    .delete {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 4px;
      right: 4px;
      cursor: pointer;
    }
  }
  .upload {
    position: relative;
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 0.5px solid rgba(0, 0, 0, 0.03);
    background: rgba(245, 246, 250, 1);
    border-radius: 4px;
    justify-content: center;
    font-size: 14px;
    color: rgba(136, 139, 148, 1);
    cursor: pointer;
    span {
      line-height: 18px;
      margin-top: 8px;
    }
  }
  .uploadReviewInitStyle {
    width: 275px;
  }
  .uploadWidth {
    width: 560px;
  }
}
.publishWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .inner {
    position: relative;
    padding-top: 50px;
  }
  .agree {
    line-height: 20px;
    padding: 8px 0;
    position: relative;
    left: -5px;
    position: absolute;
    white-space: nowrap;
    top: 0px;
    display: flex;
    align-items: center;
    .txt {
      font-size: 16px;
      &:last-child {
        color: rgba(136, 139, 148, 1);
        font-size: 14px;
      }
    }
  }
}

// 已评论列表
.reviewCompletedWrapper {
  .product {
    display: flex;
    margin-bottom: 16px;
    .imgWrapper {
      img {
        width: 60px;
        height: 60px;
      }
    }
    .productContent {
      flex: 1;
      width: 0;
      flex-shrink: 0;
      .title {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 4px;
      }
      .dimensions {
        line-height: 18px;
        font-size: 14px;
        color: rgba(136, 139, 148, 1);
      }
    }
  }
}
.reviewListBlock {
  cursor: pointer;
  .reviewsItemBlock {
    border-radius: 4px;
    padding: 12px 16px;
    border: 1px solid rgba(240, 241, 242, 1);
    margin-bottom: 16px;
  }

  .reviewsMsg {
    color: rgba(26, 26, 26, 1);
  }

  .reviewsContent {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
    color: rgba(26, 26, 26, 1);
    word-wrap: break-word;
    white-space: pre-wrap;
  }

  .basicButton {
    position: relative;
    height: 20px;
    border-radius: 4px;
    display: flex;
    justify-content: end;
    align-items: center;
    .anonymous {
      position: absolute;
      font-size: 14px;
      left: 0;
      padding-left: 20px;
      color: rgba(136, 139, 148, 1);
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 4px;
        width: 16px;
        height: 16px;
        background-size: 100% 100%;
      }
    }
    .btn {
      display: flex;
      align-items: center;
      color: rgba(106, 106, 106, 1);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      cursor: pointer;
      &.active {
        .zanIcon {
          background-size: 100% 100%;
        }
      }
      &:hover {
        &:not(.active) {
          color: var(--sys-color-Primary800);

          .zanIcon {
            svg,
            path{
              stroke: var(--sys-color-Primary800);
            };
          }

          .pinglunIcon {
            svg,
            path{
              fill: var(--sys-color-Primary800);
            };
          }

          .moreIcon {
            svg,
            path{
              fill: var(--sys-color-Primary800);
            };
          }
        }
      }
    }

    .line {
      margin: 0 8px;
      width: 0;
      height: 12px;
      border-right: 1px solid rgba(224, 225, 229, 1);
    }
  }

  .pinglunIcon {
    // width: 14px;
    // height: 14.45px;
    background-size: 100% 100%;
  }

  .moreIcon {
    width: 20px;
    height: 20px;
    background-size: 100% 100%;
  }

  .mar {
    margin-right: 5px;
  }

  .mediaZone {
    display: flex;
    gap: 12px;
    margin-bottom: 8px;
    flex-wrap: wrap;

    img {
      width: 140px;
      height: 140px;
      object-fit: cover;
      border-radius: 4px;
    }

    .videoBox{
      width: 140px;
      height: 140px;
      object-fit: cover;
      border-radius: 4px;
      overflow: hidden;
    }
  }
}

// 展示评分样式
.commentScoreRating {
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  .basicFont {
    margin-left: 16px;
    color: rgba(157, 157, 157, 1);
    font-size: 14px;
  }

  .scoreIcon {
    font-weight: 700;
    margin-right: 4px;
  }

  .scoreExtra {
    display: flex;
    align-items: center;
    color: rgba(136, 139, 148, 1);
    font-size: 12px;
    line-height: 16px;
  }

  .starIconBox {
    display: flex;
    align-items: center;
  }

  .star {
    margin-right: 4px;
    width: 16px;
    height: 16px;

    &.full {
      background-size: 100% 100%;
    }

    &.empty {
      background-size: 100% 100%;
    }

    &.half {
      background-size: 100% 100%;
    }
  }
}

// 评论结果页面样式
.reviewResultWrapper {
  width: 1264px;
  margin: 0 auto 32px;
  padding-top: 32px;
  .status {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    padding: 16px 0;
    margin-bottom: 32px;
    .iconSuccess {
      font-size: 18px;
      line-height: 26px;
      font-weight: 700;
      background-size: 26px 26px;
      padding-left: 34px;
    }
    .btnWrapper {
      display: flex;
      gap: 12px;
      margin-top: 20px;
    }
  }
  .reviewsWaiting {
    background-color: #fff;
    .h1 {
      line-height: 72px;
      padding-left: 24px;
      font-size: 24px;
      font-weight: 700;
    }
  }
}
.pageEmpty {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 309px;
}

.helpfulBtn {
  display: flex;
  align-items: center;
  color: rgba(106, 106, 106, 1);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  &.active {
    .zanIcon {
      background-size: 100% 100%;
    }
  }
  &:hover {
    &:not(.active) {
      color: var(--sys-color-Primary800);
      .zanIcon {
        svg,
        path{
          stroke: var(--sys-color-Primary800);
        };
      }
    }
  }
  .zanIcon {
    width: 14px;
    height: 15px;
    background-size: 100% 100%;
  }
}
.detailBasicButton {
  position: relative;
  height: 20px;
  border-radius: 4px;
  display: flex;
  justify-content: end;
  align-items: center;
  .anonymous {
    position: absolute;
    font-size: 14px;
    left: 0;
    color: rgba(136, 139, 148, 1);
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .btn {
    display: flex;
    align-items: center;
    color: rgba(106, 106, 106, 1);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
    &.active {
      .zanIcon {
        background-size: 100% 100%;
      }
    }
    &:hover {
      &:not(.active) {
        color: var(--sys-color-Primary800);

        .zanIcon {
          svg,
          path{
            stroke: var(--sys-color-Primary800);
          };
        }

        .pinglunIcon {
          svg,
          path{
            fill: var(--sys-color-Primary800);
          };
        }

        .moreIcon {
          svg,
          path{
            fill: var(--sys-color-Primary800);
          };
        }
      }
    }
  }

  .line {
    margin: 0 8px;
    width: 0;
    height: 12px;
    border-right: 1px solid rgba(224, 225, 229, 1);
  }
  .pinglunIcon {
    // width: 14px;
    // height: 14.45px;
    background-size: 100% 100%;
  }

  .moreIcon {
    width: 20px;
    height: 20px;
    background-size: 100% 100%;
  }

  .mar {
    margin-right: 5px;
  }

  .mediaZone {
    display: flex;
    gap: 12px;
    margin-bottom: 8px;
    flex-wrap: wrap;

    img {
      width: 140px;
      height: 140px;
      object-fit: cover;
      border-radius: 4px;
    }
  }
}
