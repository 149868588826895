#root{
  background-color: red;
}
.UK_product_card {
  width: 240px;
  padding-bottom: 12px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;

  .UK_imgTips {
    position: absolute;
    z-index: 10;

    .UK_topTagBox {
      position: relative;
      width: 36px;
      height: 45px;

      .UK_topTagIcon {
        width: 100%;
        height: 100%;
      }

      .UK_topTagNum {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 24px;
        font-weight: 400;
        line-height: 28px;
        color: #fff;
      }
    }
  }

  .UK_image_wrapper {
    position: relative;
    width: 100%;
    cursor: pointer;

    .UK_skuImg {
      width: 100%;
      aspect-ratio: 1 / 1;
      text-align: center;
      object-fit: contain;
      border-radius: 4px;
    }

    .UK_mask {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02));

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
      }

      &:hover {
        &::after {
          z-index: 1;
          background: linear-gradient(0deg, rgba(225, 225, 225, 0.1), rgba(225, 225, 225, 0.1));
        }
      }
    }
  }

  .UK_name_wrapper {
    display: flex;
    justify-content: space-between;
    height: 44px;
    margin-top: 12px;
    padding: 0 12px;
   
    cursor: pointer;

    &:hover {
      .UK_name {
        color: #FF0F23;
      }
    }

    .UK_name_box{
      flex: 1;
      text-align: left;
      font-weight: 400;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden; //溢出内容隐藏
      text-overflow: ellipsis; //文本溢出部分用省略号表示
      display: -webkit-box; //特别显示模式
      -webkit-line-clamp: 2; //行数
      line-clamp: 2;
      -webkit-box-orient: vertical; //盒子中内容竖直排列
      color: #19191B;
    }
    .UK_titleTipsDom{
      display: inline-block;
      margin-right: 2px;
    }
    .UK_name {
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
    }
    .UK_pricePromotionTag {
      margin-right: 2px;
      border-radius: 2px;
      padding: 0 2px;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: center;
      color: #fff;
      background: linear-gradient(90deg, #FF475D 0%, #FF0F23 100%);
    }
  }

  .UK_price_and_market_wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 12px;
    position: relative;
    margin-top: 4px;
    .UK_left{
      flex: 1;
      width: 0;
    }
    .UK_right{
      width: 40px;
      display: flex;
      align-items: center;
    }
  }

  .UK_priceFloor {
    text-align: left;
    height: 52px;
    display: flex; 
    flex-direction: column;

    .UK_realPrice {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      height: 28px;
      margin-right: 8px;
      font-family: JDZhengHT-EN;
      font-size: 16px;
      font-weight: 400;
      line-height: 32px;
      text-align: left;
      color: #1a1a1a;
      white-space: pre-wrap;
      overflow: hidden;
    }
    .UK_originalPrice {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      height: 18px;

      margin-right: 8px;
      font-family: JDZhengHT-EN;
      font-size: 16px;
      font-weight: 400;
      line-height: 32px;
      text-align: left;
      color: #1a1a1a;
      white-space: pre-wrap;
      overflow: hidden;
    }
  }

  .UK_cart {
    width: 32px;
    height: 32px;
    cursor: pointer;

    &:hover {
      svg,
      path {
        fill: #FF0F23
      }
    }
  }

  .UK_Market_wrapper{
    .UK_score {
      display: flex;
      align-items: center;
      text-align: left;
      margin-top: 4px;
  
      .UK_star {
        display: flex;
        align-items: center;
        
        .UK_starIcon{
          margin-right: 2px;
        }
      }
  
      .UK_tips {
        flex: 1;
        margin-left: 6px;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: #CC6B0A;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}