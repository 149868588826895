.pageLoading {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 400px;
  border-radius: 8px;

  background: #FFFFFF;
  position: relative;

  .pageLoadingContent {
    position: absolute;
    top: calc(50% - 50px);
    text-align: center;
  }
  .text {
    margin-top: 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #C2C4CC;
  }
}