.uk-pc-product-land {
  h1 {
    color: red;
  }

  .w {
    text-align: center;
    width: 1264px;
    margin: 0 auto;
  }
}

.productList {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 16px;
  row-gap: 16px;
  margin-top: 8px;
  .pricePromotionTag {
    margin-right: 2px;
    padding: 0 2px;
    border-radius: 2px;
    padding: 0 2px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    color: #fff;
    background: linear-gradient(90deg, #FF475D 0%, #FF0F23 100%);
  }
}

.topTagBox {
  position: relative;
  width: 36px;
  height: 45px;

  .topTagIcon {
    width: 100%;
    height: 100%;
  }

  .topTagNum {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    color: #fff;
  }
}

.errorDefault {
  margin: 132px auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .errorImg {
    width: 120px;
    height: 120px;
    margin-bottom: 12px;
  }

  .errorText {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: #C2C4CC;
    margin-bottom: 12px;
  }

  .tryAgain {
    border: 1px solid #888B94;
    border-radius: 4px;
    padding: 6px 12px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    cursor: pointer;
  }
}

.loading {
  display: inline-block;
  padding: 10px;
  margin: 24px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #C2C4CC;

  .loadingIcon {
    display: inline-block;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
    margin-right: 10px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(-360deg);
    }
  }
}

.more {
  display: inline-block;
  margin: 24px 0;
  height: 48px;
  border-radius: 4px;
  padding: 10px 16px;
  text-align: center;
  line-height: 28px;
  font-size: 18px;
  font-weight: 400;
  border: 1px solid #E0E1E5;
  cursor: pointer;
}

.icon {
  display: inline-block;
  width: 20px;
  height: 20px;
}

.reached {
  display: inline-block;
  margin: 24px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #C2C4CC;
}