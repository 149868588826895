.UK_product_card {
  width: 240px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;

  .UK_imgTips {
    position: absolute;
    z-index: 10;

    .UK_topTagBox {
      position: relative;
      width: 36px;
      height: 45px;

      .UK_topTagIcon {
        width: 100%;
        height: 100%;
      }

      .UK_topTagNum {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 24px;
        font-weight: 400;
        line-height: 28px;
        color: #fff;
      }
    }
  }

  .UK_image_wrapper {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0;
    cursor: pointer;

    .UK_skuImg {
      width: 100%;
      aspect-ratio: 1 / 1;
      text-align: center;
      object-fit: contain;
      border-radius: 4px;
    }
    .op50{
      opacity: 0.5;
    }
    .UK_outOfStock {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      z-index: 9999;
      background: linear-gradient(0deg, rgba(225, 225, 225, 0.02), rgba(225, 225, 225, 0.02));
      display: flex;
      justify-content: center;
      align-items: center;

      .UK_outOfStock_text {
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #fff;
        text-align: center;
        padding: 5px 11px;
        background-color: #00000080;
        border-radius: 4px;
      }
    }
    .UK_mask {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      // background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02));

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
      }

      &:hover {
        &::after {
          z-index: 1;
          background: linear-gradient(0deg, rgba(225, 225, 225, 0.1), rgba(225, 225, 225, 0.1));
        }
      }
    }
  }

  .UK_name_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 12px 0 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    cursor: pointer;

    &:hover {
      .UK_skuName {
        color: #990915;
      }
    }

    .UK_saleTipsDom {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-bottom: 8px;
    }

    .UK_skuName {
      height: 20px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #1A1A1A;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .UK_price_and_cart_wrapper {
    display: flex;
    position: relative;
  }

  .UK_left {
    flex: 1;
    width: 0;
    .UK_price {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      height: 32px;
      margin-right: 8px;
      font-family: JDZhengHT-EN;
      font-size: 16px;
      font-weight: 400;
      line-height: 32px;
      color: #1a1a1a;
      white-space: pre-wrap;
      overflow: hidden;
    }
  }

  .UK_right {
    width: 32px;
    display: flex;
    align-items: center;

    .UK_cart {
      width: 32px;
      height: 32px;
      cursor: pointer;
      color: #CC0C1C;

      &:hover {
        color: #990915;
      }
    }
  }

  .UK_activityRow {
    width: 100%;
    height: 18px;
    font-family: JDZhengHT-EN;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    overflow: hidden;
    margin-top: 6px;
    text-align: left;
  }
}