.cookiesSettingWrapper {
  .title {
    margin-top: 20px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #1A1A1A;

    .status {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #888B94;
    }
  }

  .content {
    margin-top: 12px;
  }
}